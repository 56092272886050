import React from 'react'
import Menu from '../components/menu-component'

// import * as _ from 'lodash';

import Layout from '../components/layout'
import './index.css';

const IndexPage = (props) => {
  return (
    <Layout>
      <h1>Front End Apps</h1>
      <Menu />
      <div>
          <div>
              <h3>
                This page will be a list of links to front end apps that you can try out and use. I'm very interested in Progressive Web Apps and the immense amount of technology that we have today to offload programming resources to the client, as well as handing the users more control of their own data in this way.  
              </h3>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage